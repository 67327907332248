import CRUDService from "../1-common-components/crud.service";
class CsvToDb {
  CSVToArray( strData, Delimiter ){
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    Delimiter = (Delimiter || ",");
    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + Delimiter + "|\\r?\\n|\\r|^)" +

            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

            // Standard fields.
            "([^\"\\" + Delimiter + "\\r\\n]*))"
        ),
        "gi"
        );
    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];
    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;
    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while (arrMatches = objPattern.exec( strData )){
        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[ 1 ];
        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== Delimiter
            ){
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push( [] );
        }
        var strMatchedValue;
        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[ 2 ]){
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[ 2 ].replace(
                new RegExp( "\"\"", "g" ),
                "\""
                );
        } else {
            // We found a non-quoted value.
            strMatchedValue = arrMatches[ 3 ];
        }
        // Now that we have our value string, let's add
        // it to the data array.
        arrData[ arrData.length - 1 ].push( strMatchedValue );
    }
    // Return the parsed data.
    return( arrData );
}

UploadHorizontal(file,ArrToAdd) {

  var d = $.Deferred()
  const reader = new FileReader();
  var CSVObject = this;

  reader.addEventListener('error', () => {
    return Promise.reject(`Error occurred reading file: ${selectedFile.name}`);
  });

  reader.onload = function(e){ 
    var bilanArray = CSVObject.CSVToArray(e.target.result,";")
    console.log(bilanArray)
    var columns = []
    var j=0;

    while(bilanArray[0][j])
    {
      columns.push(bilanArray[0][j]);
      j++;
    }

    for(var i=1;i<bilanArray.length;i++)
    {
      var jsonResult = {}
      for(var j=0;j<columns.length;j++)
      {
        if(bilanArray && bilanArray[i][j])
        {
          jsonResult[columns[j]] = bilanArray[i][j].replace(/,/g , "");
        }

      }
      ArrToAdd.push(jsonResult);
    }

    d.resolve(ArrToAdd)
    console.log(ArrToAdd) 
  }
  reader.readAsText(file);
  return d;
}

Upload(file,jsonToAdd,validation) {

    var d = $.Deferred()
    const reader = new FileReader();
    var CSVObject = this;

    reader.addEventListener('error', () => {
      return Promise.reject(`Error occurred reading file: ${selectedFile.name}`);
    });

    reader.onload = function(e){ 
      var bilanArray = CSVObject.CSVToArray(e.target.result,";")
      console.log(bilanArray)

      for(var i=0;i<bilanArray.length;i++)
      {
        if(bilanArray && bilanArray[i][0] && bilanArray[i][1])
        {
          jsonToAdd[bilanArray[i][0]] = parseFloat(bilanArray[i][1]);

          //splice validation property if found in array
          if(validation && validation.length>0)
          {
            var propertyParsed = bilanArray[i][0];
            propertyParsed = propertyParsed.charAt(0).toLowerCase() + propertyParsed.slice(1);
            var ind = validation.indexOf(propertyParsed)
            if(ind!=-1)
            {
              validation.splice(ind,1);
            }
          }
        }
      }

      if(validation && validation.length>0)
      {
          var err={title:"validation",message:"Le Csv n'est pas valide voici les données manquantes:" + validation}
          d.reject(err);
          // return d;
      }
      else
      {
        d.resolve(jsonToAdd)
        console.log(jsonToAdd)  
      };
    }
    reader.readAsText(file);
    return d;
  }

  CSVToDBFunction(dataTableName,file,jsonToAdd,validation)
  {
    var d = $.Deferred()
    this.Upload(file,jsonToAdd,validation).then(function(response)
    {
      CRUDService.create(response,dataTableName).then(
        (response) => {
            d.resolve(response.data);
            // e.success(response.data);
        },
        (error) => {
            d.reject(error);
          }
      );

    })
    return d;

  }

  CSVToDBFunctionHorizontal(dataTableName,file,fixedObject,validation,villes)
  {
    var d = $.Deferred()
    this.UploadHorizontal(file,[]).then(function(response)
    {
      var n = 0;
      while(Object.keys(response[n]).length>0)
      {
        if(validation && validation.length>0)
        {
          for(var i=0;i<Object.keys(response[n]).length;i++)
          {
            var propertyParsed = Object.keys(response[n])[i];
            propertyParsed = propertyParsed.charAt(0).toLowerCase() + propertyParsed.slice(1);
            var ind = validation.indexOf(propertyParsed)
            if(ind!=-1)
            {
              validation.splice(ind,1);
            }
          }
        }
        n++;
      }

      if(validation && validation.length>0)
      {
        d.reject(validation);
        return d;
      }

      var deferred = $.Deferred();
      var results = [];
      for(var i=0;i<response.length;i++)
      {
        if(Object.keys(response[i]).length>0)
        {
          var responseObject = response[i]
          if(fixedObject)
          {
            Object.assign(responseObject, fixedObject);
          }

          if(villes && responseObject.ville)
          {
            responseObject.ville = villes.find(function(v){
              return v.nomVille.toLowerCase() == responseObject.ville.toLowerCase()
            })

            responseObject.villeId = responseObject.ville?responseObject.ville.id:null
          }
          else
          {
            responseObject.ville = null
          }
      
          CRUDService.create(responseObject,dataTableName).then(
            (response) => {
                results.push(response.data)
                if(results.length == n)
                {
                  deferred.resolve(results);
                }
                // e.success(response.data);
            },
            (error) => {
                deferred.reject(error);
              }
          );
        }
      }
      // deferred.then(function(data){
      //   d.resolve(data);
      // })
      deferred.then(
        (response) => {
            d.resolve(response)
        },
        (error) => {
            var errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            d.reject(errorMessage)
        })
    })
    return d;
  }

}

export default new CsvToDb();
