<template>
  <div>
  <FileUpload ref="fileUpload" :auto="true" :showCancelButton="false" chooseLabel="Importer depuis Csv" mode="basic" name="demo[]" :customUpload="true" @uploader="myUploader($event,fileUpload)" icon="pi pi-upload" class="p-button-outlined p-button-success" />
  </div>
</template>

<script>
import FileUpload from 'primevue/fileupload';

export default {
  name: "LoadCsv",
  components: {
    FileUpload,
  },
  emits:   ['BilanLoaded'],
  data() {
    return {
    };
  },
  methods:{
    myUploader: function(e,fileUpload) {
          this.log("loading Csv file..")
          this.$emit('BilanLoaded',e); 
          this.$refs.fileUpload.clear();
          
        },
  }
};
</script>
